import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import RenderBlock from '../utils/render-block'

class IndexPage extends Component {
  render() {
    let page = this.props.data.wordpressPage
    return (
      <>
        <SEO title='Physio Pacific Paradise | Physiotherapy Clinic | Paradise Physio' bodyClass={page.slug} description={page.yoast_meta.yoast_wpseo_metadesc} />
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
      </>
    )
  }
}

export default IndexPage

export const homeQuery = graphql`
  query {
    wordpressPage(wordpress_id: {eq: 2}) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_banner {
            background_image {
              ...original
            }
            title
            subtitle
            button {
              text
              link
            }
            cta
          }
          ... on WordPressAcf_lead_form {
            title
            content
            button {
              icon
              title
              subtitle
              link
            }
            form {
              title
              subtitle
            }
          }
          ... on WordPressAcf_timeline {
            title
            subtitle
            timeline {
              image {
                ...original
              }
              code_name
              name
              position
              content
              hotdoc
            }
          }
          ... on WordPressAcf_icons {
            items {
              icon
              title
            }
          }
          ... on WordPressAcf_partners {
            title
            logos {
              icon {
                ...original
              }
            }
          }
          ... on WordPressAcf_items {
            title
            subtitle
            items {
              image {
                ...original
              }
              title
              link
            }
          }
          ... on WordPressAcf_simple_pricing {
            title
            pricing {
              title
              price
            }
          }
          ... on WordPressAcf_more_information {
            title
            info {
              title
              subtitle
              link {
                url {
                  source_url
                }
              }
            }
          }
          ... on WordPressAcf_image_title_content {
            image {
              ...original
            }
            title
            content
          }
          ... on WordPressAcf_map {
            title
            content
            google_map {
              lat
              lng
            }
          }
        }
      }
    }
  }
`

